var render = function render(){var _vm=this,_c=_vm._self._c;return _c('DoseCardBase',{ref:"baseCard",attrs:{"session-response":_vm.sessionResponse},scopedSlots:_vm._u([{key:"title",fn:function(){return [_vm._v("Carbohydrate factors adjustment")]},proxy:true},{key:"tabOneText",fn:function(){return [_vm._v("Adjust carb factors")]},proxy:true}])},[_c('v-form',{ref:"bolusCarbCountPrescriptionForm",model:{value:(_vm.formValid),callback:function ($$v) {_vm.formValid=$$v},expression:"formValid"}},[_c('v-card-text',[_c('v-row',{attrs:{"no-gutters":""}},[_c('v-col',{staticClass:"pt-2 pb-5 pl-6",attrs:{"md":"10"}},[_vm._v(" Enter "),_c('strong',[_vm._v("insulin-to-carb ratio")]),_vm._v(" and "),_c('strong',[_vm._v("insulin sensitivity factor")])])],1),_c('v-row',[_c('v-col',{staticClass:"mb-3"},[_c('FormLabel',{attrs:{"large":true}},[_vm._v("Select unit for blood glucose "),_c('v-tooltip',{attrs:{"top":""},scopedSlots:_vm._u([{key:"activator",fn:function({ on, attrs }){return [_c('v-icon',_vm._g(_vm._b({attrs:{"color":"secondary","dark":""}},'v-icon',attrs,false),on),[_vm._v("mdi-information")])]}}])},[_c('span',[_vm._v("Make sure the selected unit"),_c('br'),_vm._v(" is the same as the one on the back"),_c('br'),_vm._v(" of the patient’s blood glucose meter.")])])],1),_c('div',{staticClass:"d-flex align-center justify-start"},[_c('v-radio-group',{staticClass:"boxed-radio-row radio-two-options",attrs:{"column":false,"hide-details":true,"dense":"","rules":_vm.bloodGlucoseUnitRules},on:{"change":function($event){return _vm.onChange('PRESCRIBE_DOSE:BLOOD_GLUCOSE_UINT', $event)}},model:{value:(_vm.prescription.bloodGlucoseUnit),callback:function ($$v) {_vm.$set(_vm.prescription, "bloodGlucoseUnit", $$v)},expression:"prescription.bloodGlucoseUnit"}},[_c('v-radio',{staticClass:"radio-option col",class:{
                  selected: _vm.bloodGlucoseUnit === _vm.smbgValueUnit.mg_dl,
                },attrs:{"label":_vm.smbgValueUnit.mg_dl,"value":_vm.smbgValueUnit.mg_dl}}),_c('v-radio',{staticClass:"radio-option col",class:{
                  selected: _vm.bloodGlucoseUnit === _vm.smbgValueUnit.mmol_L,
                },attrs:{"label":_vm.smbgValueUnit.mmol_L,"value":_vm.smbgValueUnit.mmol_L}})],1),(!_vm.selectBloodGlucoseUnitPending)?_c('label',{staticClass:"ml-4 font-size-14"},[_c('strong',[_vm._v("Target BG value: ")]),_vm._v(" "+_vm._s(_vm.targetBgValue))]):_vm._e()],1)],1)],1),_c('v-row',[_c('v-col',{staticClass:"px-2",attrs:{"sm":"6","md":"4"}},[_c('CarbCountInput',{attrs:{"blood-glucose-unit":_vm.bloodGlucoseUnit,"dosing-display-unit":_vm.prescription.bolus_breakfast.dosingDisplayUnit,"insulinSensitivity":_vm.prescription.bolus_breakfast.insulinSensitivityFactor,"carbRatio":_vm.prescription.bolus_breakfast.insulinToCarbRatio,"requiredText":"Breakfast ratio and factor are required","disabled":_vm.selectBloodGlucoseUnitPending,"deactivated":!_vm.prescription.bolus_breakfast.enabled,"validation-rules":_vm.getValidationRules('bolus_breakfast')},on:{"toggled-active":function($event){return _vm.onToggle($event, 'bolus_breakfast')},"ratio-input":function($event){return _vm.onInput('bolus_breakfast', { insulinToCarbRatio: $event })},"sensitivity-input":function($event){return _vm.onInput('bolus_breakfast', {
                insulinSensitivityFactor: $event,
              })},"sensitivity-change":function($event){return _vm.onChange($event, 'PRESCRIBE_DOSE:BOLUS_BREAKFAST_SENSITIVITY')},"ratio-change":function($event){return _vm.onChange($event, 'PRESCRIBE_DOSE:BOLUS_BREAKFAST_RATIO')}}},[_vm._v(" "+_vm._s(_vm.$t('time-point-short.bolus_breakfast'))+" ")])],1),_c('v-col',{staticClass:"px-2",attrs:{"sm":"6","md":"4"}},[_c('CarbCountInput',{attrs:{"blood-glucose-unit":_vm.bloodGlucoseUnit,"dosing-display-unit":_vm.prescription.bolus_lunch.dosingDisplayUnit,"insulinSensitivity":_vm.prescription.bolus_lunch.insulinSensitivityFactor,"carbRatio":_vm.prescription.bolus_lunch.insulinToCarbRatio,"requiredText":"Lunch ratio and factor are required","disabled":_vm.selectBloodGlucoseUnitPending,"deactivated":!_vm.prescription.bolus_lunch.enabled,"validation-rules":_vm.getValidationRules('bolus_lunch')},on:{"toggled-active":function($event){return _vm.onToggle($event, 'bolus_lunch')},"ratio-input":function($event){return _vm.onInput('bolus_lunch', { insulinToCarbRatio: $event })},"sensitivity-input":function($event){return _vm.onInput('bolus_lunch', {
                insulinSensitivityFactor: $event,
              })},"sensitivity-change":function($event){return _vm.onChange($event, 'PRESCRIBE_DOSE:BOLUS_LUNCH_SENSITIVITY')},"ratio-change":function($event){return _vm.onChange($event, 'PRESCRIBE_DOSE:BOLUS_LUNCH_RATIO')}}},[_vm._v(" "+_vm._s(_vm.$t('time-point-short.bolus_lunch'))+" ")])],1),_c('v-col',{staticClass:"px-2",attrs:{"sm":"6","md":"4"}},[_c('CarbCountInput',{attrs:{"blood-glucose-unit":_vm.bloodGlucoseUnit,"dosing-display-unit":_vm.prescription.bolus_dinner.dosingDisplayUnit,"insulinSensitivity":_vm.prescription.bolus_dinner.insulinSensitivityFactor,"carbRatio":_vm.prescription.bolus_dinner.insulinToCarbRatio,"requiredText":"Dinner ratio and factor are required","disabled":_vm.selectBloodGlucoseUnitPending,"deactivated":!_vm.prescription.bolus_dinner.enabled,"validation-rules":_vm.getValidationRules('bolus_dinner')},on:{"toggled-active":function($event){return _vm.onToggle($event, 'bolus_dinner')},"ratio-input":function($event){return _vm.onInput('bolus_dinner', { insulinToCarbRatio: $event })},"sensitivity-input":function($event){return _vm.onInput('bolus_dinner', {
                insulinSensitivityFactor: $event,
              })},"sensitivity-change":function($event){return _vm.onChange($event, 'PRESCRIBE_DOSE:BOLUS_DINNER_SENSITIVITY')},"ratio-change":function($event){return _vm.onChange($event, 'PRESCRIBE_DOSE:BOLUS_DINNER_RATIO')}}},[_vm._v(" "+_vm._s(_vm.$t('time-point-short.bolus_dinner'))+" ")])],1)],1),_c('v-row',[_c('v-col',{staticClass:"d-flex align-center justify-end mr-6"},[_c('v-input',{attrs:{"error-messages":_vm.bolusCountError,"hide-details":""}}),(_vm.bolusCountError)?_c('div',{staticClass:"error--text mr-6 font-size-14"},[_vm._v(" "+_vm._s(_vm.bolusCountError)+" ")]):_vm._e(),_c('div',[_c('v-btn',{attrs:{"data-testid":"add-confirm-button","rounded":"","color":"secondary","disabled":!_vm.formValid,"loading":_vm.isLoading,"type":"submit","elevation":"1"},on:{"click":function($event){$event.preventDefault();_vm.createPrescription(_vm.getDoses(), _vm.bloodGlucoseUnit)}}},[_vm._v(" Continue ")])],1)],1)],1)],1)],1)],1)
}
var staticRenderFns = []

export { render, staticRenderFns }