<template>
  <DoseCardBase ref="baseCard" :session-response="sessionResponse">
    <template #title>Carbohydrate factors adjustment</template>
    <template #tabOneText>Adjust carb factors</template>

    <v-form v-model="formValid" ref="bolusCarbCountPrescriptionForm">
      <v-card-text>
        <v-row no-gutters>
          <v-col md="10" class="pt-2 pb-5 pl-6">
            Enter <strong>insulin-to-carb ratio</strong> and
            <strong>insulin sensitivity factor</strong>
          </v-col>
        </v-row>

        <v-row>
          <v-col class="mb-3">
            <FormLabel :large="true"
              >Select unit for blood glucose
              <v-tooltip top>
                <template v-slot:activator="{ on, attrs }">
                  <v-icon color="secondary" dark v-bind="attrs" v-on="on"
                    >mdi-information</v-icon
                  >
                </template>
                <span
                  >Make sure the selected unit<br />
                  is the same as the one on the back<br />
                  of the patient’s blood glucose meter.</span
                >
              </v-tooltip>
            </FormLabel>
            <div class="d-flex align-center justify-start">
              <v-radio-group
                :column="false"
                :hide-details="true"
                class="boxed-radio-row radio-two-options"
                v-model="prescription.bloodGlucoseUnit"
                dense
                :rules="bloodGlucoseUnitRules"
                @change="onChange('PRESCRIBE_DOSE:BLOOD_GLUCOSE_UINT', $event)"
              >
                <v-radio
                  class="radio-option col"
                  :class="{
                    selected: bloodGlucoseUnit === smbgValueUnit.mg_dl,
                  }"
                  :label="smbgValueUnit.mg_dl"
                  :value="smbgValueUnit.mg_dl"
                />

                <v-radio
                  class="radio-option col"
                  :class="{
                    selected: bloodGlucoseUnit === smbgValueUnit.mmol_L,
                  }"
                  :label="smbgValueUnit.mmol_L"
                  :value="smbgValueUnit.mmol_L"
                />
              </v-radio-group>

              <label
                v-if="!selectBloodGlucoseUnitPending"
                class="ml-4 font-size-14"
                ><strong>Target BG value: </strong> {{ targetBgValue }}</label
              >
            </div>
          </v-col>
        </v-row>

        <v-row>
          <v-col sm="6" md="4" class="px-2">
            <CarbCountInput
              :blood-glucose-unit="bloodGlucoseUnit"
              :dosing-display-unit="
                prescription.bolus_breakfast.dosingDisplayUnit
              "
              :insulinSensitivity="
                prescription.bolus_breakfast.insulinSensitivityFactor
              "
              :carbRatio="prescription.bolus_breakfast.insulinToCarbRatio"
              requiredText="Breakfast ratio and factor are required"
              :disabled="selectBloodGlucoseUnitPending"
              :deactivated="!prescription.bolus_breakfast.enabled"
              @toggled-active="onToggle($event, 'bolus_breakfast')"
              :validation-rules="getValidationRules('bolus_breakfast')"
              @ratio-input="
                onInput('bolus_breakfast', { insulinToCarbRatio: $event })
              "
              @sensitivity-input="
                onInput('bolus_breakfast', {
                  insulinSensitivityFactor: $event,
                })
              "
              @sensitivity-change="
                onChange($event, 'PRESCRIBE_DOSE:BOLUS_BREAKFAST_SENSITIVITY')
              "
              @ratio-change="
                onChange($event, 'PRESCRIBE_DOSE:BOLUS_BREAKFAST_RATIO')
              "
            >
              {{ $t('time-point-short.bolus_breakfast') }}
            </CarbCountInput>
          </v-col>

          <v-col sm="6" md="4" class="px-2">
            <CarbCountInput
              :blood-glucose-unit="bloodGlucoseUnit"
              :dosing-display-unit="prescription.bolus_lunch.dosingDisplayUnit"
              :insulinSensitivity="
                prescription.bolus_lunch.insulinSensitivityFactor
              "
              :carbRatio="prescription.bolus_lunch.insulinToCarbRatio"
              requiredText="Lunch ratio and factor are required"
              :disabled="selectBloodGlucoseUnitPending"
              :deactivated="!prescription.bolus_lunch.enabled"
              @toggled-active="onToggle($event, 'bolus_lunch')"
              :validation-rules="getValidationRules('bolus_lunch')"
              @ratio-input="
                onInput('bolus_lunch', { insulinToCarbRatio: $event })
              "
              @sensitivity-input="
                onInput('bolus_lunch', {
                  insulinSensitivityFactor: $event,
                })
              "
              @sensitivity-change="
                onChange($event, 'PRESCRIBE_DOSE:BOLUS_LUNCH_SENSITIVITY')
              "
              @ratio-change="
                onChange($event, 'PRESCRIBE_DOSE:BOLUS_LUNCH_RATIO')
              "
            >
              {{ $t('time-point-short.bolus_lunch') }}
            </CarbCountInput>
          </v-col>

          <v-col sm="6" md="4" class="px-2">
            <CarbCountInput
              :blood-glucose-unit="bloodGlucoseUnit"
              :dosing-display-unit="prescription.bolus_dinner.dosingDisplayUnit"
              :insulinSensitivity="
                prescription.bolus_dinner.insulinSensitivityFactor
              "
              :carbRatio="prescription.bolus_dinner.insulinToCarbRatio"
              requiredText="Dinner ratio and factor are required"
              :disabled="selectBloodGlucoseUnitPending"
              :deactivated="!prescription.bolus_dinner.enabled"
              @toggled-active="onToggle($event, 'bolus_dinner')"
              :validation-rules="getValidationRules('bolus_dinner')"
              @ratio-input="
                onInput('bolus_dinner', { insulinToCarbRatio: $event })
              "
              @sensitivity-input="
                onInput('bolus_dinner', {
                  insulinSensitivityFactor: $event,
                })
              "
              @sensitivity-change="
                onChange($event, 'PRESCRIBE_DOSE:BOLUS_DINNER_SENSITIVITY')
              "
              @ratio-change="
                onChange($event, 'PRESCRIBE_DOSE:BOLUS_DINNER_RATIO')
              "
            >
              {{ $t('time-point-short.bolus_dinner') }}
            </CarbCountInput>
          </v-col>
        </v-row>

        <v-row>
          <v-col class="d-flex align-center justify-end mr-6">
            <v-input :error-messages="bolusCountError" hide-details />
            <div v-if="bolusCountError" class="error--text mr-6 font-size-14">
              {{ bolusCountError }}
            </div>

            <div>
              <v-btn
                data-testid="add-confirm-button"
                rounded
                @click.prevent="
                  createPrescription(getDoses(), bloodGlucoseUnit)
                "
                color="secondary"
                :disabled="!formValid"
                :loading="isLoading"
                type="submit"
                elevation="1"
              >
                Continue
              </v-btn>
            </div>
          </v-col>
        </v-row>
      </v-card-text>
    </v-form>
  </DoseCardBase>
</template>

<script>
import addPrescriptionMixin from './addPrescriptionMixin'
import DoseCardBase from './_DoseCardBase'

import CarbCountInput from '@/components/patient/add-patient/initial-dose/_CarbCountInput'
import FormLabel from '@/components/patient/add-patient/shared/FormLabel'
import prescribeCarbCountDoseMixin from '@/components/mixins/prescribeCarbCountDoseMixin'
import smbgValueUnit from '@/constants/smbgValueUnit'
import isNumeric from '@/utils/isNumeric'

export default {
  name: 'BolusCarbCountRecurringDose',
  components: { CarbCountInput, FormLabel, DoseCardBase },
  mixins: [addPrescriptionMixin, prescribeCarbCountDoseMixin],
  data() {
    return {
      bloodGlucoseUnitRules: [v => !!v || 'Blood glucose unit is required'],
      formValid: false,
      prescription: this.createInitialPrescription(),
      smbgValueUnit,
    }
  },
  computed: {
    bolusCountError() {
      if (this.numberOfActiveBolus < 2)
        return 'At least 2 bolus doses should be active for the patient.'

      return null
    },
    bloodGlucoseUnit() {
      return this.prescription.bloodGlucoseUnit
    },
    numberOfActiveBolus() {
      return (
        Number(this.prescription.bolus_breakfast.enabled) +
        Number(this.prescription.bolus_lunch.enabled) +
        Number(this.prescription.bolus_dinner.enabled)
      )
    },
    selectBloodGlucoseUnitPending() {
      return !this.prescription.bloodGlucoseUnit
    },
  },
  methods: {
    createInitialPrescription() {
      const doseConfiguration = this.sessionResponse.doses
      const result = {
        bloodGlucoseUnit: this.sessionResponse.bloodGlucoseUnit,
      }
      for (const config of doseConfiguration) {
        result[config.treatment.timepoint] = {
          insulinToCarbRatio: null,
          insulinSensitivityFactor: null,
          enabled: config.isActive,
          dosingDisplayUnit: config.treatment.dosingDisplayUnit,
        }
      }
      return result
    },
    getDoses() {
      let doses = []
      const doseConfiguration = this.sessionResponse.doses
      for (const config of doseConfiguration) {
        const dose = this.prescription[config.treatment.timepoint]

        if (
          dose.enabled &&
          isNumeric(dose.insulinSensitivityFactor) &&
          isNumeric(dose.insulinToCarbRatio)
        ) {
          doses.push({
            timepoint: config.treatment.timepoint,
            insulinSensitivityFactor: parseFloat(dose.insulinSensitivityFactor),
            insulinToCarbRatio: parseFloat(dose.insulinToCarbRatio),
            auditEntries: this.auditLog.getAuditEntries(),
            isActive: true,
          })
        }
      }
      return doses
    },
    getValidationRules(timepoint) {
      return this.sessionResponse.doses.find(
        x => x.treatment.timepoint === timepoint
      )?.treatment
    },
    isDirty() {
      return this.sessionResponse.doses.some(
        x =>
          this.prescription[x.treatment.timepoint].insulinToCarbRatio ||
          this.prescription[x.treatment.timepoint].insulinSensitivityFactor
      )
    },
    resetForm() {
      this.$refs.bolusCarbCountPrescriptionForm.reset()
    },
    onChange(value, name) {
      this.auditLog.log(name, value)
    },
    onInput(timepoint, object) {
      this.prescription[timepoint] = {
        ...this.prescription[timepoint],
        ...object,
      }
    },
    onToggle(enabled, name) {
      this.prescription[name].enabled = enabled
      this.auditLog.log(`PRESCRIBE_DOSE:${name.toUpperCase()}_ENABLED`, enabled)
    },
  },
}
</script>

<style scoped lang="scss">
.radio-two-options {
  width: 33%;
}
</style>
